<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'

interface Props {
  count: number
  badge?: string
  text?: string
  buttonText?: string
  variant?: 'primary' | 'secondary'
  to?: string | RouteLocationRaw
  loading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  count: undefined,
  badge: undefined,
  text: undefined,
  buttonText: undefined,
  variant: 'primary',
  to: undefined,
  loading: false,
})

const route = useRoute()
</script>

<template>
  <!-- Card -->
  <NuxtLink
    :to="to ?? route.fullPath"
    role="button"
    class="hover:border-primary hover:bg-primary/5 hover:ring-primary focus:border-primary focus:bg-primary/5 focus:ring-primary focus-visible:border-primary focus-visible:bg-primary/5 focus-visible:ring-primary group flex shrink-0 cursor-pointer flex-col justify-center gap-4 overflow-hidden rounded-xl border border-gray-800 p-5 backdrop-blur-md backdrop-saturate-[180%] before:absolute before:inset-0 before:z-[-2] before:bg-cover before:transition-all before:content-[''] hover:ring-opacity-20 hover:before:opacity-100 focus:ring-opacity-20 focus:before:opacity-100 focus-visible:outline-none focus-visible:ring-opacity-20 focus-visible:before:opacity-100 md:flex-1"
    :class="{
      'bg-warning/5 ring-warning/20 ml-1 ring-2': variant === 'primary',
      'pointer-events-none': !to,
    }"
  >
    <!-- Content -->
    <div class="flex gap-3 self-stretch">
      <!-- Counter -->
      <div v-if="loading" class="flex items-center justify-center">
        <Spinner class="fill-primary h-8 w-8" />
      </div>
      <span v-else class="text-5xl font-medium">{{ count }}</span>
      <div class="flex items-center justify-between self-stretch">
        <div class="gap grid-cols-1">
          <!-- Badge -->
          <div class="font-bold">
            {{ text }}
          </div>
          <div
            class="group-hover:text-primary"
            :class="{
              'text-warning': variant === 'primary',
            }"
          >
            {{ badge }}
          </div>
        </div>
      </div>
    </div>
  </NuxtLink>
</template>
